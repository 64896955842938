<template>
  <div class="addres">
    <div class="title">地址管理</div>
    <div class="titename">添加新地址</div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label=" 收货人:" prop="consignee">
        <el-input v-model="form.consignee" placeholder="请输入收货人姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系电话:" prop="mobile">
        <el-input v-model="form.mobile" placeholder="请输入手机号码" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="所在地区:" prop="region">
        <picker @change="changeCity"></picker>
      </el-form-item>
      <div class="gostar">
        <el-form-item label="详细地址:" prop="address">
          <el-input class="details" v-model="form.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
      </div>
      <div class="gostar">
        <el-form-item label="默认地址:" prop="isDefault">
          <el-checkbox class="details" v-model="form.isDefault">设为默认地址</el-checkbox>
        </el-form-item>
      </div>
      <el-form-item class="save">
        <el-button class="details" type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
    <div class="titename">
      已保存的地址
      <span class="morewrap">
        （您已创建
        <span class="addnum" v-text="list.length"></span>个收货地址，最多可支持创建
        <span class="addnum">8</span> 个地址）
      </span>
    </div>
    <!-- 地址添加 -->
    <div class="ulwrap">
      <ul class="liwrap" v-for="(item, index) in list" :key="index" @mouseover="hoverIndex = index" @mouseout="hoverIndex = -1">
            <div @click="adressWrap(index,$event)">
                <li class="mone flex align-center">
                  <span class="name flex-1" v-text="item.consignee"></span>
                  <span class="modify">
                    <!-- <span class="mody" @click.stop="mody">修改</span> -->
                    <span class="del" @click.stop="del(index,item.id)">删除</span>
                    <span class="defat" v-if="item.isDefault">默认地址</span>
                  </span>
                </li>
                <li class="mtwo" v-text="item.mobile"></li>
                <li class="mthree">{{item.address | removeComma}}</li>
                <i :class="{'hoverI':index==hoverIndex&&item.isDefault==false}" class="defAddres">设为默认地址</i>
                <img class="selet" src="../../../assets/image/gouxuan.png" alt srcset v-if="item.isDefault">
            </div>
      </ul>
    </div>
  </div>
</template>
<script>
import Picker from '@/components/common/citySelect'
import { setInterval } from 'timers';
import * as api from '@/api/api';
export default {
  components: { Picker },
  data() {
    return {
      hoverIndex: -1, //表示当前hover的是第几个li 初始为 -1 或 null 不能为0 0表示第一个li

      checked: true,
      list: [],
      form: {
        consignee: '',
        mobile: '',
        areaId0: '',
        areaId1: '',
        areaId2: '',
        address: '',
        isDefault: false,
        region: ['', '', '']
      },
      rules: {
        consignee: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入收货人联系电话', trigger: 'blur' },
          { required: true, message: '收货人联系电话格式输入不正确', trigger: 'blur', pattern: /^[1]([3-9])[0-9]{9}$/}
        ],
        region: [
          {
            validator: (rule, value, callback) => {
              if (value[0] === '') {
                callback(new Error('请选择省'))
              } else if (value[1] === '') {
                callback(new Error('请选择市'))
              } else if (value[2] === '') {
                callback(new Error('请选择区域'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    this.queryList()
  },
  methods: {
    mody(){ //点击了修改地址
      alert("点击了修改")
    },

    adressWrap(i,e){ //点击地址列表
      if(this.list!=""&&this.list[i].isDefault==false){
          this.list[i].isDefault=true;
         this.$http.get('userConsignee/updateIsDefault?userConsigneeId='+this.list[i].id).then(res => { //设置默认地址
            this.queryList();
          })
      }
    },
     enter(index){
        this.aaa = true;
        this.current = index;
      },
      leave(index){
        this.aaa = false;
        this.current = null;
      },

    del(i,id){ //删除地址

      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
        console.log(444)
          this.list.splice(i, 1); //删除当前点击的dom数据
          let Params = {userConsigneeId:id}
            api.deleteById(Params).then(res => {
               if(res.data.code==0){
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
              }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

    },
    queryList() {
      let self=this;
      this.$http.get('/userConsignee/getSiteByUserId').then(res => {
        const { data } = res.data
        self.list = data;  //加载时接口展示地址信息
      })
    },
    changeCity(e) {
      const { province, city, region } = e
      // console.log(province)
      this.form.areaId0 = province
      this.form.areaId1 = city
      this.form.areaId2 = region
      this.form.region = [province, city, region]
    },
    onSubmit() { //点击保存地址
      setTimeout(()=>{ //延时请求后台地址回显接口
          this.queryList();
      },500)
      if(this.list.length<8){ //大于8条不添加地址数据
          console.log(this.list)
          this.$refs['form'].validate(valid => {
              if (valid) {
                delete this.form.region
                this.$http.post('userConsignee/addSite', this.form).then(res => {

                })
              } else {
                return false
              }
          })
      }else{
        this.$message({
          message: '最多可以保存8个收货地址！',
          type: 'warning'
        });
      }
    }
  }
}
</script>

<style lang='scss' scoped>
 .defAddres{
   display: none;
 }
.hoverI{
    display: block;
    color: #d82229;
    margin-top: 2px;
}

.clickBg{
    background: blue;
    color: #fff;
}
$theme-color: #d82229;
.addres {
  padding: 30px 20px;
  .title {
    font-size: 18px;
    color: #333333;
    padding-bottom: 28px;
  }
  .titename {
    border-left: 3px solid $theme-color;
    font-size: 16px;
    padding-left: 10px;
    .morewrap {
      font-size: 14px;
      color: #999999;
      .addnum {
        color: $theme-color;
      }
    }
  }
  /deep/.el-form {
    margin: 22px 0 35px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
    .el-form-item {
      .el-form-item__label{
        width: 100px!important;
      }
      .el-form-item__content {
        width: 276px;
        display: block;
        float: left;
      }
      .el-input--suffix {
        min-width: 90px;
      }
    }
  }
  .el-button--primary{
    background: #d82229;
    border:1px solid #d82229;
  }
  .ulwrap {
    margin: 30px 0 0;
    display: flex;
    flex-wrap: wrap;
    .liwrap {
      padding: 0;
      width: 240px;
      height: 156px;
      font-size: 14px;
      list-style: none;
      padding: 0 15px;
      margin: 0 20px 20px 0;
      cursor: pointer;
      box-sizing: border-box;
      border-right: 1px solid #eaeaea;
      background: url(../../../assets/image/bg-address.png) no-repeat;
      position: relative;
      .mone {
        color: #333333;
        margin: 20px 0 10px;
        .name {
          font-size: 16px;
          display: inline-block;
        }
        .modify {
          font-size: 12px;
          color: #333333;
          .mody {
            color: #333333;
            margin-right: 15px;
            cursor: pointer;
            &:nth-child(2) {
              color: #ff0000;
            }
          }
          .del{
            margin-right: 5px;
          }
          .defat {
            color: $theme-color;
            cursor: pointer;
          }
        }
      }
      .mtwo {
        font-size: 16px;
        color: #666666;
      }
      .mthree {
        font-size: 14px;
        color: #999999;
        margin: 18px 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
      }
      .selet {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

/deep/ .save .el-form-item__content{
  margin-left: 28px !important;
}
 /deep/ .el-form-item__label::before{
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
  }

 .gostar /deep/ .el-form-item__label::before{
    content: '';
  }
</style>
